<template>
	<resources-index :resources="resources" type="case_study" plural="case_studies" title="Case Studies"></resources-index>
</template>

<script>

import ResourcesIndex from '@/views/resources/Index'
import {sync} from "vuex-pathify";
export default {
	name: "CaseStudiesIndex",
	components: {
		ResourcesIndex
	},
	computed: {
		resources: sync('case_studies/case_studies'),
	},
}
</script>